 import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1280.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">

<path d="M6100 12405 c-262 -15 -422 -32 -700 -74 -187 -29 -553 -114 -690
-161 -14 -4 -86 -27 -160 -50 -377 -118 -865 -335 -1153 -511 -26 -16 -50 -29
-52 -29 -4 0 -248 -152 -305 -190 -280 -188 -610 -462 -895 -744 -522 -517
-963 -1163 -1258 -1846 -54 -124 -159 -392 -172 -440 -4 -14 -22 -68 -40 -120
-37 -106 -110 -375 -149 -550 -104 -460 -154 -1020 -136 -1498 11 -256 13
-293 40 -517 20 -163 26 -199 60 -385 103 -562 285 -1096 552 -1620 127 -250
197 -371 364 -625 65 -100 312 -430 355 -476 8 -8 39 -43 69 -79 302 -356 577
-613 987 -921 221 -167 357 -255 631 -412 626 -358 1407 -622 2120 -716 347
-46 488 -55 842 -55 328 0 460 8 790 50 246 32 581 98 750 148 25 8 63 18 85
24 418 113 930 325 1335 555 456 258 910 604 1275 971 147 148 173 176 305
325 166 188 349 434 516 691 200 307 399 695 530 1034 74 189 83 213 109 296
14 47 30 94 35 105 12 26 93 320 114 415 10 41 24 102 31 135 43 182 90 502
117 790 17 183 17 756 0 940 -59 639 -183 1179 -396 1722 -148 374 -309 692
-528 1038 -49 77 -105 163 -124 190 -20 28 -57 79 -82 115 -106 149 -337 432
-462 565 -109 117 -259 261 -390 377 -175 155 -177 156 -260 222 -41 33 -95
76 -120 96 -366 295 -990 642 -1492 829 -190 70 -546 184 -658 210 -193 45
-356 78 -545 111 -88 15 -273 37 -410 49 -132 12 -519 32 -575 30 -19 -1 -136
-7 -260 -14z m-1952 -1482 l2 -223 89 0 c75 0 90 3 95 17 4 10 6 110 5 223
l-1 205 81 3 81 3 0 -536 0 -535 -80 0 c-57 0 -80 4 -80 13 0 6 -2 116 -3 242
l-2 230 -92 3 -93 3 0 -246 0 -245 -85 0 -85 0 0 535 0 536 83 -3 82 -3 3
-222z m841 175 c7 -29 27 -141 47 -248 20 -107 49 -267 65 -355 17 -88 39
-209 50 -270 11 -60 22 -118 25 -127 5 -16 -3 -18 -80 -18 -95 0 -89 -4 -101
85 -3 28 -8 62 -11 78 l-6 27 -93 0 c-100 0 -104 -3 -105 -67 0 -12 -5 -45
-11 -73 l-11 -50 -84 0 c-82 0 -84 1 -80 23 15 69 44 225 111 592 42 226 78
420 80 433 5 21 10 22 99 22 l95 0 10 -52z m639 -410 l-3 -463 -27 -51 c-41
-79 -81 -99 -194 -99 -80 0 -95 3 -127 25 -57 39 -79 88 -84 189 -2 47 0 106
4 131 l9 45 64 0 65 0 5 -116 c5 -127 14 -149 61 -149 59 0 59 -1 59 491 0
245 3 449 7 452 3 4 42 7 85 7 l79 0 -3 -462z m529 10 c-4 -497 -4 -503 -64
-569 -40 -44 -82 -59 -168 -59 -80 0 -136 27 -171 80 -23 35 -28 55 -32 125
-2 46 -1 109 3 140 l7 56 66 -3 67 -3 5 -115 c6 -123 12 -145 39 -153 32 -8
59 11 70 50 7 24 11 198 11 471 l0 432 86 0 85 0 -4 -452z m803 425 c59 -27
113 -87 136 -154 17 -48 19 -87 19 -359 0 -328 -1 -339 -55 -423 -20 -32 -45
-55 -82 -74 -51 -26 -61 -28 -215 -31 l-163 -4 0 537 0 537 158 -4 c130 -4
165 -8 202 -25z m605 15 c2 -7 13 -62 24 -123 11 -60 45 -247 76 -415 31 -168
63 -341 70 -385 8 -44 17 -92 21 -107 l6 -28 -85 0 -85 0 -14 93 -14 92 -92 3
c-106 3 -98 11 -115 -104 l-12 -79 -83 -3 c-63 -2 -82 0 -82 10 0 8 18 113 41
233 22 121 56 306 75 410 18 105 44 241 56 303 l21 112 94 0 c66 0 95 -4 98
-12z m725 -53 l0 -65 -85 0 -85 0 0 -470 0 -470 -85 0 -85 0 0 470 0 470 -85
0 -85 0 0 65 0 65 255 0 255 0 0 -65z m420 61 c0 -2 15 -87 34 -188 19 -100
53 -284 76 -408 23 -124 50 -271 61 -328 28 -153 33 -142 -61 -142 -91 0 -80
-12 -100 118 l-12 72 -94 0 c-52 0 -94 -1 -94 -3 0 -2 -5 -30 -10 -63 -6 -32
-13 -74 -16 -91 l-5 -33 -85 0 c-77 0 -85 2 -80 18 3 9 10 46 16 82 19 111
159 862 170 918 l11 52 95 0 c52 0 94 -2 94 -4z m-1314 -1446 c604 -14 837
-26 955 -49 53 -11 163 -27 244 -36 88 -10 166 -25 192 -36 24 -10 70 -23 102
-28 32 -5 81 -21 108 -34 27 -14 70 -28 96 -32 26 -4 63 -18 83 -31 19 -13 62
-33 97 -44 34 -11 78 -31 97 -44 19 -12 47 -26 62 -29 14 -4 39 -18 55 -31 15
-13 44 -32 64 -42 76 -40 195 -123 239 -166 16 -16 32 -28 37 -28 6 0 75 -57
105 -88 16 -16 57 -55 111 -105 31 -30 57 -59 57 -65 0 -7 6 -12 13 -12 6 0
23 -16 35 -35 13 -18 35 -41 48 -50 13 -8 24 -22 24 -29 0 -8 16 -29 35 -47
19 -19 35 -39 35 -46 0 -6 17 -32 38 -56 20 -24 46 -59 57 -77 45 -75 62 -100
78 -117 9 -11 23 -39 32 -63 8 -24 26 -57 39 -74 12 -17 29 -49 36 -71 7 -22
23 -59 37 -83 13 -23 31 -68 39 -98 7 -30 23 -67 34 -82 11 -15 27 -59 35 -99
8 -40 25 -88 36 -108 13 -21 27 -74 36 -133 7 -53 22 -115 33 -137 13 -27 24
-92 35 -205 9 -91 23 -194 31 -230 37 -162 36 -804 -1 -946 -8 -32 -22 -120
-30 -194 -15 -146 -30 -227 -46 -246 -5 -7 -16 -56 -24 -110 -9 -56 -24 -112
-35 -131 -11 -19 -27 -66 -36 -104 -9 -39 -29 -96 -45 -126 -16 -30 -29 -65
-29 -78 0 -12 -8 -33 -19 -46 -10 -13 -24 -42 -31 -64 -6 -22 -30 -70 -51
-106 -21 -36 -39 -70 -39 -76 0 -6 -10 -24 -23 -40 -13 -15 -33 -48 -45 -73
-12 -25 -31 -53 -42 -62 -11 -10 -20 -24 -20 -31 0 -8 -12 -24 -26 -38 -14
-13 -32 -36 -40 -51 -8 -15 -48 -66 -89 -114 -41 -47 -75 -88 -75 -92 0 -4
-14 -15 -30 -24 -17 -9 -30 -23 -30 -30 0 -8 -10 -20 -21 -28 -12 -8 -27 -21
-33 -29 -12 -16 -95 -97 -141 -139 -16 -15 -43 -35 -58 -45 -16 -10 -38 -28
-50 -40 -32 -33 -97 -82 -136 -102 -19 -9 -43 -26 -54 -36 -12 -10 -26 -19
-33 -19 -6 0 -39 -18 -72 -40 -33 -22 -65 -40 -72 -40 -7 0 -34 -13 -59 -29
-25 -16 -71 -37 -101 -46 -30 -10 -68 -28 -85 -40 -16 -13 -51 -26 -77 -29
-26 -4 -67 -18 -92 -31 -25 -13 -85 -31 -133 -40 -49 -8 -113 -24 -144 -34
-32 -12 -123 -27 -215 -36 -87 -9 -184 -23 -214 -30 -126 -32 -371 -38 -1593
-39 l-1209 -1 -19 24 c-18 23 -19 53 -19 1036 0 627 4 1023 10 1039 5 15 21
29 37 33 15 4 243 8 506 8 l479 0 19 -24 c18 -22 19 -46 19 -556 0 -353 4
-542 11 -563 10 -29 14 -31 77 -38 92 -10 1030 3 1172 15 63 6 216 18 339 26
172 11 228 18 241 30 10 9 64 25 121 36 57 11 114 26 126 35 13 8 57 27 98 43
41 16 86 38 101 50 15 12 32 21 38 21 6 0 33 16 60 35 27 19 55 35 63 35 7 0
27 16 44 35 18 19 36 35 40 35 4 0 39 30 77 68 39 37 77 73 84 80 7 6 29 30
48 52 19 22 50 55 68 73 17 19 32 38 32 44 0 7 11 24 23 40 13 15 36 52 52 81
15 28 32 52 36 52 4 0 13 17 19 38 7 20 28 57 46 81 19 24 34 50 34 57 0 23
32 107 46 121 14 14 34 75 34 105 0 9 13 50 29 90 18 44 34 110 41 163 6 50
19 113 30 142 29 79 44 345 37 693 -6 289 -14 378 -42 451 -7 20 -18 80 -25
135 -8 73 -18 115 -40 161 -17 34 -30 72 -30 85 0 13 -15 55 -33 93 -19 39
-40 87 -47 109 -7 21 -23 54 -36 74 -13 19 -29 52 -34 72 -6 21 -16 40 -24 43
-7 3 -24 27 -37 54 -52 107 -263 348 -394 448 -54 42 -174 118 -208 133 -15 6
-30 19 -33 27 -4 8 -14 15 -24 15 -30 0 -103 30 -121 49 -10 11 -37 22 -61 26
-58 9 -126 30 -165 50 -20 10 -78 20 -155 26 -68 5 -184 18 -258 29 -74 11
-193 24 -264 30 -164 13 -1196 13 -1216 0 -13 -8 -16 -96 -20 -663 -5 -593 -7
-655 -22 -679 l-17 -26 -486 4 c-381 2 -490 6 -502 16 -42 35 -43 46 -43 769
0 682 0 691 20 696 20 5 20 14 20 419 l0 413 33 4 c70 9 1441 6 1873 -3z
m-3703 -1810 l2 -974 25 -13 c20 -10 390 -13 1842 -12 l1818 0 15 22 c13 19
15 90 15 538 0 283 2 523 6 532 3 9 16 19 27 23 12 4 242 7 511 8 480 1 489 1
502 -19 11 -17 13 -286 14 -1465 1 -795 -2 -1458 -6 -1475 -4 -16 -11 -35 -16
-41 -6 -8 -151 -12 -508 -13 l-499 -2 -13 23 c-10 18 -14 126 -18 458 -3 239
-9 442 -13 452 -5 9 -18 19 -30 22 -43 11 -3592 7 -3625 -5 -22 -7 -34 -20
-41 -42 -7 -21 -9 -466 -8 -1272 2 -1350 4 -1283 -53 -1312 -19 -10 -133 -13
-481 -13 -470 0 -509 3 -529 40 -11 21 -14 5502 -3 5513 3 4 244 6 535 5 l529
-3 2 -975z"/>
<path d="M4880 10909 c0 -7 -40 -291 -55 -394 -8 -55 -15 -106 -15 -112 0 -9
21 -13 75 -13 84 0 80 -5 65 85 -6 33 -22 143 -36 245 -13 101 -27 186 -29
189 -3 2 -5 2 -5 0z"/>
<path d="M6770 10613 l0 -420 48 4 c65 6 87 19 109 66 16 37 18 71 18 352 0
275 -2 314 -17 345 -23 45 -47 60 -109 67 l-49 6 0 -420z"/>
<path d="M7432 10673 c-18 -131 -33 -248 -32 -260 0 -22 4 -23 70 -23 59 0 70
3 70 17 0 29 -61 457 -68 481 -5 16 -19 -59 -40 -215z"/>
<path d="M8606 10879 c-12 -75 -66 -465 -66 -476 0 -9 22 -13 75 -13 41 0 75
3 75 7 0 3 -4 34 -10 67 -5 34 -21 147 -36 251 -14 105 -27 192 -29 194 -2 1
-6 -12 -9 -30z"/>
</g>
</svg>

 

    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}